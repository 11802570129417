import { useCallback, useMemo, useState } from "react";
import { Option } from "./types";

export const usePDPConfiguratorData = props => {
  const { availableOptions, handleOptionsSelect, isLoading } = props;
  const [seletedoptions, setSelectedOptions] = useState<Option[]>([]);
  const excludedTypes = ["Color", "Fabric", "Leather"];

  const isExcludedType = type => {
    return excludedTypes.includes(type);
  };

  const processedOptions = useMemo(() => {
    return availableOptions?.reduce((acc, { type, options }) => {
      if (isExcludedType(type)) {
        return acc;
      }
      acc[type] = {
        options: options.slice().sort((a, b) => a.sortPriority - b.sortPriority)
      };
      return acc;
    }, {});
  }, [availableOptions]);

  const handleSelect = useCallback((option: Option, availableOptions) => {
    setSelectedOptions(prevOptions => {
      const filteredOptions = prevOptions?.filter(
        prevOption => prevOption?.type !== option?.type
      );
      return [...filteredOptions, option];
    });
    handleOptionsSelect(option?.id, availableOptions);
  }, []);

  return {
    excludedTypes,
    processedOptions,
    seletedoptions,
    isExcludedType,
    setSelectedOptions,
    handleSelect,
    isLoading
  };
};
