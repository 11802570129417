import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    atcContainer: {
      marginBottom: "8px",
      marginTop: "68px"
    },
    label: {
      color: "#000000",
      textDecoration: "underline"
    },
    imageContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: "30px"
    },
    dialogImageContainer: {
      maxWidth: "291px",
      alignItems: "center"
    },
    selectOptions: {
      paddingBottom: "10px",
      paddingLeft: "10px"
    },
    atcOptionContainer: {
      paddingTop: "29px",
      paddingBottom: "8px",
      alignItems: "center",
      justifyContent: "center"
    },
    atcMessageBox: {
      paddingTop: "5px"
    },
    atcDisplayName: {
      paddingTop: "20px"
    },
    atcDescBox: {
      paddingTop: "23px"
    },
    atcPriceBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: "6px"
    },
    atcQtyContainer: {
      paddingTop: "10px"
    },
    spoMessage: {
      lineHeight: "150% !important",
      textTransform: "uppercase",
      paddingTop: "20px",
      paddingBottom: "10px"
    }
  })
);
