export const getSuperscriptSuffix = (day: number) => {
  if (day > 3 && day < 21) return "th"; // Covers 11th to 19th
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export const formatDateParts = (date: Date) => {
  if (!date || !(date instanceof Date) || isNaN(date.getTime())) {
    return { month: "", day: "-", suffix: "" };
  }

  const options: Intl.DateTimeFormatOptions = { month: "short" }; // Only get the month
  const formatter = new Intl.DateTimeFormat("en-US", options);
  const month = formatter.format(date); // Get the month
  const day = date.getDate(); // Extract the day
  const suffix = getSuperscriptSuffix(day); // Get the suffix
  return { month, day, suffix };
};
