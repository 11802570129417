import { useRelatedProductCardDataV2 } from "./useRelatedProductCardDataV2";

export const usePDPConfiguratorAndCTAData = props => {
  const {
    swatchData,
    data,
    priceRangeDisplay,
    postalCode,
    onZipChange,
    onOrderTypeInfo
  } = props;

  const {
    loading,
    lineItem,
    cartDialog,
    monogramOrder,
    pli,
    customProductConfig,
    giftCardTo,
    giftCardFrom,
    multiSkuComponentsDataV2,
    skuDetails,
    qty,
    customSelectedOptions,
    metaSkuPricing,
    confirmed,
    addonDataAddToCart,
    panelProdData,
    panelProdConfigurationData,
    isDisabled,
    selectedOptions,
    swatchOptionStatus,
    isMultiSku,
    onProductConfigChange,
    handleAddToCartClick,
    handleCloseAddToCartDialog,
    setConfirmed,
    onAddOnConfigChange,
    getPreBillMessage,
    onProductSwatchConfigChange
  } = useRelatedProductCardDataV2(props as any);

  const handleSwatchSelect = (swatch: ProductSwatch | any) => {
    if (!swatch?.options) return;
    const selectedOpts = swatch?.options?.reduce(
      (acc, opt) => ({ ...acc, [opt?.optionType]: opt?.id }),
      {}
    );
    const selectedSwatchOption = lineItem.availableOptions.reduce(
      (acc, opts) => {
        if (selectedOpts[opts.type]) {
          const option = opts?.options?.find(
            opt => opt?.id === selectedOpts[opts.type]
          );
          acc.push({ ...option, status: swatch?.status });
        }
        return acc;
      },
      []
    );
    if (selectedSwatchOption.length > 0) {
      onProductSwatchConfigChange(swatch?.swatchOptionId, selectedSwatchOption);
    }
  };

  const memberPrice =
    pli?.sku?.info?.skuPriceInfo?.memberPrice ??
    priceRangeDisplay?.memberPrices?.[0];
  const regularPrice =
    pli?.sku?.info?.skuPriceInfo?.listPrice ??
    priceRangeDisplay?.listPrices?.[0];
  const orderType = lineItem?.sku?.fulfillmentEta?.lineType || "";
  const deliveryDate = (
    lineItem?.sku?.fulfillmentEta?.inventoryMessage ||
    lineItem?.sku?.fulfillmentEta?.lineItemMessage
  )
    ?.split(" ")
    ?.pop();

  return {
    data,
    swatchData,
    priceRangeDisplay,
    loading,
    lineItem,
    postalCode,
    onZipChange,
    onOrderTypeInfo,
    cartDialog,
    monogramOrder,
    pli,
    customProductConfig,
    giftCardTo,
    giftCardFrom,
    multiSkuComponentsDataV2,
    skuDetails,
    qty,
    customSelectedOptions,
    metaSkuPricing,
    confirmed,
    addonDataAddToCart,
    panelProdData,
    panelProdConfigurationData,
    isDisabled,
    selectedOptions,
    memberPrice,
    regularPrice,
    orderType,
    deliveryDate,
    swatchOptionStatus,
    isMultiSku,
    onProductConfigChange,
    handleAddToCartClick,
    handleCloseAddToCartDialog,
    setConfirmed,
    onAddOnConfigChange,
    getPreBillMessage,
    handleSwatchSelect
  };
};
