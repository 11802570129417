import React from "react";
import Price from "./Price";
import memoize from "@RHCommerceDev/utils/memoize";
import PDPTypography from "./PDPTypography";
import { DataProps, PDPProductDetailsProps } from "./type";
import { clsx as cn } from "clsx";

const PDPProductDetails: React.FC<PDPProductDetailsProps> = ({
  data,
  priceProps,
  className = "",
  productData
}) => {
  const sortedComponents = Object.keys(data)
    ?.map(key => ({ key, sortOrder: (data as DataProps)[key]?.sortOrder }))
    ?.sort((a, b) => a.sortOrder - b.sortOrder);

  return (
    <div
      id={"component-pdp-product-details"}
      data-testid={"component-pdp-product-details"}
      className={cn(`pl-10 pr-[33.3%] flex flex-col`, className)}
    >
      {(productData?.designerName || productData?.instock?.hasInStock) && (
        <PDPTypography
          name={
            productData?.designerName ||
            (productData?.instock?.hasInStock ? "IN Stock" : "")
          }
          className="text-[18px] font-primary-rh-thin -tracking-[0.05em]"
        />
      )}

      <PDPTypography
        name={productData?.collectionName}
        className="text-[40px] font-primary-rhhairline -tracking-[0.05em]"
      />

      <div className={`flex items-baseline flex-wrap gap-x-[0.25em]`}>
        {sortedComponents.map(({ key }) =>
          key === "price" ? (
            <Price key={key} {...priceProps} />
          ) : data?.[key]?.name ? (
            <PDPTypography key={key} name={data?.[key]?.name} />
          ) : null
        )}
      </div>
    </div>
  );
};

export default memoize(PDPProductDetails);
