import React, { FC } from "react";
import memoize from "@RHCommerceDev/utils/memoize";
import PDPConfiguratorOptions from "./PDPConfiguratorOptions";
import { usePDPConfiguratorData } from "./usePDPConfiguratorData";
import { PDPConfiguratorProps } from "./types";

export const PDPConfigurator: FC<PDPConfiguratorProps> = props => {
  const { processedOptions, handleSelect, isLoading } =
    usePDPConfiguratorData(props);
  return (
    <div
      id={"component-pdp-configurator"}
      data-testid={"component-pdp-configurator"}
      className="relative flex flex-wrap gap-14 pt-[21.96px] pb-[3px] flex-1"
    >
      {Object?.keys(processedOptions)?.map(key => {
        const options = processedOptions[key]?.options;
        const values = options ? Object.values(options) : [];
        return (
          <PDPConfiguratorOptions
            key={key}
            label={key}
            values={Object.values(processedOptions[key]?.options)}
            variant={key === "Width" ? "square" : "flex"}
            defaultValue={""}
            onSelect={opt => handleSelect(opt, values)}
            isDisabled={isLoading}
          />
        );
      })}
    </div>
  );
};
export default memoize(PDPConfigurator);
