import React from "react";
import classNames from "classnames";
import memoize from "@RHCommerceDev/utils/memoize";
import RHInfoIconV2 from "@RHCommerceDev/icon-info-v2";
import { SwatchCarouselProps } from "./types";
import clsx from "clsx";

const SwatchCarousel: React.FC<SwatchCarouselProps> = ({
  swatches,
  currentSelectedSwatch,
  itemsToShow,
  height,
  width,
  SwatchDetail,
  carouselRef,
  endRef,
  handleMouseLeave,
  handleImageClick,
  handleMouseDown,
  handleMouseUp,
  handleMouseMove,
  handleImageDragStart,
  handleShowInfo
}) => {
  const baseClasses = "border-t-[1.5px] border-b-[1.5px] border-transparent";
  const beforeClasses =
    "before:absolute before:bg-white before:inset-x-0 before:top-0 before:opacity-20 before:h-1 before:z-10";
  const afterClasses =
    "after:absolute after:bg-white after:inset-x-0 after:bottom-0 after:opacity-20 after:h-1 after:z-10";
  const selectedSwatchClass = `!border-black ${beforeClasses} ${afterClasses}`;

  return (
    <div
      id={"component-pdp-swatch-carousel_swatch_carousel"}
      data-testid={"component-pdp-swatch-carousel_swatch_carousel"}
      className={classNames(
        "w-full overflow-hidden whitespace-nowrap flex items-center relative active:cursor-grabbing",
        { ["hidden"]: SwatchDetail }
      )}
      ref={carouselRef}
      onMouseLeave={handleMouseLeave}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
    >
      <div className="flex transition-all duration-200 ease-linear">
        {swatches.slice(0, itemsToShow).map((swatch, index) => {
          const isSelected = swatch?.status === "selected";
          const isUnavailable = swatch?.status === "unavailable";
          return (
            <div
              id={`swatch-${index}`}
              className={clsx(
                "relative",
                baseClasses,
                isSelected && selectedSwatchClass,
                isUnavailable && "opacity-30"
              )}
              onClick={() => handleImageClick(swatch)}
              key={swatch?.swatchId}
              onDragStart={handleImageDragStart}
              style={{
                height,
                width,
                background: `url(${swatch?.swatchImageLg?.url}) center top`
              }}
            >
              <div
                className={classNames(
                  `absolute w-full h-full opacity-0 hover:opacity-100 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col p-2 justify-center items-center gap-1 mix-blend-difference text-white`,
                  {
                    ["!opacity-100"]: isSelected
                  }
                )}
              >
                <h6 className="m-0 text-xs font-primary-rhsans text-center font-light leading-4 tracking-[0.24px] whitespace-normal w-24">
                  {swatch?.swatchGroupName}
                </h6>

                <span className="text-xs font-primary-rhthin font-thin text-center leading-4 tracking-[0.24px] w-24">
                  {swatch?.title}
                </span>
                <RHInfoIconV2
                  className="cursor-pointer"
                  onClick={(e: any) => {
                    e.stopPropagation();
                    handleShowInfo(swatch);
                  }}
                />
              </div>
            </div>
          );
        })}
        <div ref={endRef} />
      </div>
    </div>
  );
};

export default memoize(SwatchCarousel);
