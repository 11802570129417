import { useEnv } from "hooks/useEnv";
import { useEffect, useState, useCallback, useMemo } from "react";
import { UseProductAddonDataParams } from "./types";
import { usePageContent } from "customProviders/LocationProvider";
import { getMemberSavingsText } from "utils/memberSavingsTextUtils";

const useProductAddonData = ({
  data,
  opts,
  productLineItemOptions,
  handleAddonData
}: UseProductAddonDataParams) => {
  const env = useEnv();
  const { pageContent } = usePageContent();

  const PDP_DEFAULT_QTY = Number(env.FEATURE_PDP_DEFAULT_QUANTITY);
  const [qty, setQty] = useState<number>(PDP_DEFAULT_QTY || 1);
  const [selectedOptions, setSelectedOptions] = useState<
    ProductAvailableOption[]
  >([]);

  const selectedAddOnDisplayOption = useMemo(
    () =>
      productLineItemOptions?.map(optionSet => {
        const matchingOptionSet = opts?.find(
          secondOptionSet =>
            secondOptionSet.optionTypeId === optionSet.optionTypeId
        );
        const matchingOptionIds = optionSet?.options
          ?.filter(option => option.status === "selected")
          .map(option => option.id);
        return matchingOptionSet?.options.filter(option =>
          matchingOptionIds.includes(option.id)
        );
      })?.[0]?.[0]?.value,
    [productLineItemOptions, opts]
  );

  const setOptionsVisibility = useCallback(
    optionsSet =>
      productLineItemOptions?.map(optionSet => {
        const matchingOptionSet = optionsSet?.find(
          secondOptionSet =>
            secondOptionSet.optionTypeId === optionSet.optionTypeId
        );

        const matchingOptionIds = optionSet?.options
          .filter(option => option.status === "selected")
          .map(option => option.id);

        const isOptionSetVisible =
          (matchingOptionSet &&
            matchingOptionSet.options.some(option =>
              matchingOptionIds.includes(option.id)
            )) ||
          false;

        return {
          optionTypeId: optionSet.optionTypeId,
          optionType: optionSet.type,
          isOptionSetVisible,
          matchedOptionIds: matchingOptionIds
        };
      }),
    [productLineItemOptions]
  );

  useEffect(() => {
    const selectedOpts =
      opts
        ?.map(
          availableOption =>
            availableOption.options.find(
              option => option.status === "selected"
            ) as ProductAvailableOption
        )
        ?.filter(availableOption => !!availableOption) ?? [];

    setSelectedOptions(selectedOpts);
    handleAddonData(
      {
        productId: data?.id,
        productSelected: true,
        selectedOptions: selectedOpts,
        optionsLength: opts?.length,
        qty: qty ? qty : 1
      },
      {
        panelProdComputation: true
      }
    );
  }, [opts]);

  //handler for select option
  const onProductConfigChange = useCallback(
    (
      value?: string,
      options?: ProductAvailableOption[],
      productId?: string
    ) => {
      const selectedOption = options?.find(option => option.id === value);
      const selectedOpts = [
        ...selectedOptions?.filter(
          selectedOption =>
            !options?.find(option => option.id === selectedOption.id)
        ),
        ...(selectedOption ? [selectedOption] : [])
      ];

      // const finalSelectedOptions = selectedOpts?.length
      //   ? selectedOpts
      //   : getPreSelectedOption(opts).flat();

      setSelectedOptions(selectedOpts);

      handleAddonData(
        {
          productId: productId!,
          productSelected: true,
          selectedOptions: selectedOpts,
          optionsLength: opts?.length,
          qty: qty ? qty : 1
        },
        {
          panelProdComputation: true
        }
      );
    },

    [opts, selectedOptions, qty, handleAddonData]
  );

  const handleQuantitySelected = useCallback(
    (quantity?: number) => {
      handleAddonData(
        {
          productId: data?.id,
          productSelected: quantity !== 0,
          selectedOptions: selectedOptions,
          optionsLength: opts?.length,
          qty: quantity!
        },
        {
          panelProdComputation: true
        }
      );
    },
    [selectedOptions, opts, data, handleAddonData]
  );

  const dynamicMemberSavingsText = getMemberSavingsText(
    pageContent,
    Number(data?.saleInfo?.percentSaleSkus),
    Number(data?.saleInfo?.memberSavings?.memberSavingsMin),
    Number(data?.saleInfo?.memberSavings?.memberSavingsMax)
  );

  const saleMessageForPriceComponent = dynamicMemberSavingsText;

  return {
    qty,
    setQty,
    selectedAddOnDisplayOption,
    setOptionsVisibility,
    onProductConfigChange,
    handleQuantitySelected,
    selectedOptions,
    saleMessageForPriceComponent
  };
};

export default useProductAddonData;
