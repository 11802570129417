import React from "react";
import memoize from "@RHCommerceDev/utils/memoize";
import { PDPCTAProps } from "./type";
import PriceRange from "./components/PriceRange";
import OrderDetails from "./components/OrderDetails";
import CTAButton from "./components/CTAButton";

const PDPCTA: React.FC<PDPCTAProps> = ({
  memberPrice,
  regularPrice,
  deliveryDate,
  zipCode,
  onZipChange,
  onOrderTypeInfo,
  orderType,
  isDisabled = false,
  handlePlaceOrderClick
}) => {
  return memberPrice && regularPrice ? (
    <div
      className="relative flex flex-row items-center justify-center"
      id={"component-pdp-cta"}
      data-testid={"component-pdp-cta"}
    >
      <div className="flex flex-col items-start gap-y-[3px] px-4">
        {/* Prices */}
        <PriceRange memberPrice={memberPrice} regularPrice={regularPrice} />

        {/* Order Details */}
        <OrderDetails
          deliveryDate={deliveryDate}
          zipCode={zipCode}
          orderType={orderType}
          onCustomOrderClick={onOrderTypeInfo}
          onZipCodeClick={onZipChange}
        />
      </div>

      {/* CTA Button */}
      <CTAButton isDisabled={isDisabled} onClick={handlePlaceOrderClick} />
    </div>
  ) : (
    <></>
  );
};

export default memoize(PDPCTA);
