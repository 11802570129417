import { ArrowsVisible } from "component-rh-carousel/types";
import ThumbnailCarousel from "pages/Rooms/ThumbnailCarousel";
import React, { Dispatch, FC, SetStateAction, useEffect, useRef } from "react";
import { GridListProps, Theme, useMediaQuery } from "utils/material-ui-core";

export interface ImageThumbnailListProps extends GridListProps {
  direction?: "row" | "column";
  images: ProductAlternateImage[];
  index: number | undefined;
  imageCarousels?: boolean;
  onChangeIndex?: (index) => void;
  parentBaseId?: string;
  infiniteScroll?: boolean;
  isPdp?: boolean;
  extraOperations?: {
    isArrowsClicked?: Boolean;
    setIsArrowsClicked?: Dispatch<SetStateAction<Boolean>>;
  };
}

export const ImageThumbnailWrapper: FC<ImageThumbnailListProps> = ({
  images,
  index,
  onChangeIndex,
  imageCarousels,
  infiniteScroll = true,
  extraOperations,
  isPdp
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));

  const carouselProps = {
    slidesToShow: 5,
    slidesToScroll: 5,
    vertical: !imageCarousels && mdUp ? true : false,
    verticalSwiping: !imageCarousels && mdUp ? true : false,
    arrowsVisible: "always" as ArrowsVisible,
    infinite: infiniteScroll
  };
  useEffect(() => {
    setTimeout(() => {
      ref?.current?.scrollIntoView({
        inline: "center",
        behavior: "smooth"
      });
    });
  }, [ref]);

  return (
    <>
      <div
        id={"component-image-thumbnail-list"}
        key={"component-image-thumbnail-list"}
        data-testid={"component-image-thumbnail-list"}
        className={`w-full`}
      >
        <ThumbnailCarousel
          icon={"yes"}
          images={images}
          slide={{ id: index }}
          currentIndex={index}
          carouselProps={carouselProps}
          noCondiments={false}
          desktopMatchesQuery={true}
          onSelectSlide={data => onChangeIndex?.(data?.id)}
          carouselStyle={{
            imageStyle: "bg-transparent",
            // prevArrow: "-25px",
            // nextArrow: "20px"
            arrowStyle: "-25px"
          }}
          isPdp={isPdp}
          extraOperations={extraOperations}
        />
      </div>
    </>
  );
};

ImageThumbnailWrapper.defaultProps = {};

export default ImageThumbnailWrapper;
