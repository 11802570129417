import React, { FC, useMemo, useState } from "react";
import RHStickyModal from "component-rh-sticky-modal";
import CookiePermissionBanner from "component-rh-cookie-permission-banner";
import CookieSelectionModal from "component-rh-cookie-selection-modal";
import { useEnv } from "hooks/useEnv";
import yn from "yn";
import { REQUIRED_PERMISSION_COUNTRIES } from "utils/constants";
import { Utils } from "@adobe/aem-react-editable-components";
import { useCookiesWithPermission } from "hooks/useCookiesWithPermission";
import { useCookiePreferencesAtomValue } from "hooks/atoms";
import memoize from "utils/memoize";
import { useAppId } from "hooks/useAppId";
import getCountryFromUrl, { getLanguageFromUrl } from "utils/getCountryFromUrl";
import { usePageContentNoProxy } from "customProviders/LocationProvider";
import { processModelResponse } from "graphql-client/queries/get-aem-model";
import { aemFetchHook } from "aem/fetchModel";
import { client } from "graphql-client";
import {
  languageToggleExcludedCountries,
  cookieAemLanguageMapper
} from "resources/countries-config.json";

const isEditor = Utils.isInEditor();

const env = useEnv();
const FEATURE_SUPPRESS_UK = yn(env.FEATURE_SUPPRESS_UK);
const FEATURE_INTERNATIONAL_LANGUAGE_PREFERENCE = yn(
  env?.FEATURE_INTERNATIONAL_LANGUAGE_PREFERENCE
);

const RHCookiePermissionBannerWrapper: FC = () => {
  const { isEstore, isConcierge } = useAppId();
  const [isOpenedCookiePreferences, setOpenedCookiePreferences] =
    useState(false);
  const { raw: currentLanguage } = getLanguageFromUrl();
  const [cookieLanguage, setCookieLanguage] = useState<string>(
    currentLanguage.toUpperCase() || ""
  );
  const cookiePreferences = useCookiePreferencesAtomValue();
  const { cookiePermissionCountry } = useCookiesWithPermission();
  const country = getCountryFromUrl();
  const showBanner = useMemo(() => {
    if (yn(cookiePreferences?.userSavedCookiePreferences)) {
      return false;
    }

    return REQUIRED_PERMISSION_COUNTRIES?.includes(cookiePermissionCountry);
  }, [cookiePermissionCountry, cookiePreferences?.userSavedCookiePreferences]);

  const showLanguageToggle =
    isEstore &&
    !languageToggleExcludedCountries.includes(country) &&
    !FEATURE_INTERNATIONAL_LANGUAGE_PREFERENCE;

  let { pageContent, loading: initialLoading } = usePageContentNoProxy();

  const languageCode = useMemo(
    () => cookieAemLanguageMapper[cookieLanguage],
    [cookieLanguage]
  );

  const isLangEnglish = languageCode === "en";

  // Always call aemFetchHook to avoid page crash, but handle the case with skip variable
  const aemFetchResult = aemFetchHook({
    path: `/rh/${country.toLowerCase()}/${languageCode.toLowerCase()}/admin/cookie-modal`,
    isJSPPage: false,
    isPage: true,
    graphqlClient: client,
    skip: isLangEnglish ? true : false
  });

  pageContent = isLangEnglish
    ? pageContent?.cookieModal
    : processModelResponse(aemFetchResult?.data);

  const loading =
    languageCode === "en" ? initialLoading : aemFetchResult.loading;

  const cookieEnabled = !FEATURE_SUPPRESS_UK && showBanner;

  return cookieEnabled && !isEditor ? (
    <>
      {isEstore &&
        typeof isEstore !== "undefined" &&
        !isConcierge &&
        !isOpenedCookiePreferences && (
          <RHStickyModal>
            <CookiePermissionBanner
              cookieLanguageProps={
                showLanguageToggle
                  ? {
                      onSelectLanguage: value => setCookieLanguage(value),
                      languageTogglePageContent: pageContent,
                      selectedLanguage: cookieLanguage,
                      loading: loading
                    }
                  : {}
              }
              openCookieSelectionModal={value =>
                setOpenedCookiePreferences(value)
              }
              showBanner={showBanner}
            />
          </RHStickyModal>
        )}
      <CookieSelectionModal
        open={isOpenedCookiePreferences}
        setCookieSelectionModal={value => setOpenedCookiePreferences(value)}
        languageTogglePageContent={showLanguageToggle ? pageContent : null}
      />
    </>
  ) : null;
};

export default memoize(RHCookiePermissionBannerWrapper);
